import React from "react"
import articleAsideStyle from "./style.module.scss"

const LocatedIn = ({ categories, filterSearch }) => {
  return (
    <div className={articleAsideStyle.container}>
      <h4 className={articleAsideStyle.text}>LOCATED IN</h4>
      <div className={articleAsideStyle.list}>
        {categories.map((item, ix) => (
          <div key={ix}>
            <button
              className={articleAsideStyle.category}
              onClick={() => filterSearch("category", item.id, item.name)}
            >
              {item.name}
            </button>
            <span className={articleAsideStyle.count}>({item.count})</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LocatedIn
