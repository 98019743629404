import React from "react"
import { Link } from "gatsby"

import previousAndNextArticleStyle from "./style.module.scss"

const PreviousAndNextArticle = ({ older, newer }) => {
  return (
    <div className={previousAndNextArticleStyle.sector}>
      {older ? (
        <Link
          to={older.node.path}
          className={`${previousAndNextArticleStyle.titleContainer} ${previousAndNextArticleStyle.older}`}
        >
          <div className={previousAndNextArticleStyle.subtitles}>OLDER</div>
          <div
            className={previousAndNextArticleStyle.titles}
            dangerouslySetInnerHTML={{ __html: older.node.title }}
          />
        </Link>
      ) : (
        <div
          className={`${previousAndNextArticleStyle.titleContainer} ${previousAndNextArticleStyle.older}`}
        />
      )}
      {newer ? (
        <Link
          to={newer.node.path}
          className={`${previousAndNextArticleStyle.titleContainer} ${previousAndNextArticleStyle.newer}`}
        >
          <div className={previousAndNextArticleStyle.subtitles}>NEWER</div>
          <div
            className={previousAndNextArticleStyle.titles}
            dangerouslySetInnerHTML={{ __html: newer.node.title }}
          />
        </Link>
      ) : (
        <div
          className={`${previousAndNextArticleStyle.titleContainer} ${previousAndNextArticleStyle.newer}`}
        />
      )}
    </div>
  )
}

export default PreviousAndNextArticle
