import React from "react"
import articleAsideStyle from "./style.module.scss"

const SharePost = ({ wpPost, templateData, fbId }) => {
  const socialMedia = media => {
    let socialLink
    switch (media) {
      case "facebook-logo":
        socialLink = `https://www.facebook.com/dialog/share?
        app_id=${fbId}&
        display=popup&
        href=https://blog.grio.com${wpPost.path}`
        break
      case "twitter-logo":
        socialLink = `https://twitter.com/intent/tweet?text=${wpPost.title}%20${wpPost.link}`
        break
      case "linkedin-logo":
        socialLink = `https://www.linkedin.com/shareArticle?mini=true&url=${wpPost.link}&title=${wpPost.title}`
        break
      default:
        socialLink = `#`
    }
    return socialLink
  }

  return (
    <div className={articleAsideStyle.container}>
      <h4 className={articleAsideStyle.text}>SHARE POST</h4>
      <div className={articleAsideStyle.socialMediaIcons}>
        {templateData.socialMediaIcons.map((item, index) => (
          <div className={articleAsideStyle.alignItem} key={index}>
            <a
              href={socialMedia(item.title)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={articleAsideStyle.icon}
                src={item.file.url}
                alt={item.title}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SharePost
