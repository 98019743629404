import React from "react"
import articleAsideStyle from "./style.module.scss"

const TagsArticle = ({ tags, filterSearch }) => {
  return (
    <div className={articleAsideStyle.container}>
      <h4 className={articleAsideStyle.title}>TAGS</h4>
      <div className={articleAsideStyle.list}>
        {tags.map((item, ix) => (
          <div key={ix}>
            <button
              onClick={() => filterSearch("tags", item.id, item.name)}
              className={articleAsideStyle.category}
            >
              {item.name}
            </button>
            <span className={articleAsideStyle.count}>({item.count})</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TagsArticle
