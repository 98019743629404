import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Footer from "../components/footer"
import ArticlePageTemplate from "../components/article-page-template"

export default ({ data, pageContext }) => {
  const mod = data.contentfulV4BlogPage
  const wpPost = data.wordpressPost
  const allWordpressCategory = data.allWordpressCategory
  const allWordpressTag = data.allWordpressTag
  return (
    <div>
      <SEO
        description={mod.metaDescription}
        title={`${mod.browserPageTitle} | ${wpPost.title}`}
        keywords={mod.keywords}
      />
      <ArticlePageTemplate
        wpPost={wpPost}
        templateData={mod}
        categories={allWordpressCategory}
        allTags={allWordpressTag}
        fbId={pageContext.fbId}
        blogEndPoint={pageContext.blogEndPoint}
        older={pageContext.previous}
        newer={pageContext.next}
      />
      <Footer
        showFooterExtended={mod.showFooterExtended}
        footerExtendedContent={mod.footerExtended}
      />
    </div>
  )
}

export const query = graphql`
  query($pathMasked: String!) {
    wordpressPost(path: { eq: $pathMasked }) {
      categories {
        name
        id
        count
      }
      slug
      title
      wordpress_id
      content
      path
      link
      date(formatString: "MMMM DD, YYYY")
      author {
        name
        slug
        path
        id
      }
      tags {
        id
        slug
        path
        name
        link
        count
      }
    }
    contentfulV4BlogPage {
      socialMediaIcons {
        fixed {
          src
        }
        file {
          url
        }
        title
      }
      backgroundImage {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed {
          src
        }
        file {
          url
        }
        title
      }
      name
      linkText
      by
      datePostedText
      metaDescription
      keywords
      browserPageTitle
      slug
      showFooterExtended
      footerExtended {
        content {
          id
          link
          linkText
          title
          description
          icon {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            id
          }
          arrow {
            fluid(maxWidth: 20, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            id
          }
        }
      }
    }
    allWordpressCategory {
      totalCount
      nodes {
        slug
        path
        name
        link
        taxonomy {
          id
          slug
          name
        }
        count
        id
      }
    }
    allWordpressTag {
      totalCount
      nodes {
        slug
        path
        name
        link
        taxonomy {
          id
          slug
          name
        }
        count
        id
      }
    }
  }
`
