import React from "react"
import articleAsideStyle from "./style.module.scss"
import Categories from "../../blog/categories"
import SearchBar from "../../blog/search"
import { navigate } from "gatsby"
import DatePosted from "./date-posted"
import SharePost from "./share-post"
import LocatedIn from "./located-in"
import TagsArticle from "./tags"

const ArticleAside = ({ wpPost, templateData, categories, fbId }) => {
  const filterSearch = (key, val, name) => {
    navigate("/", {
      state: {
        filter: {
          key,
          val,
          name,
        },
      },
    })
  }

  return (
    <React.Fragment>
      <DatePosted date={wpPost.date} />
      <SharePost wpPost={wpPost} templateData={templateData} fbId={fbId} />
      <SearchBar filterSearch={filterSearch} />
      <LocatedIn categories={wpPost.categories} filterSearch={filterSearch} />
      {wpPost.tags ? (
        <TagsArticle tags={wpPost.tags} filterSearch={filterSearch} />
      ) : null}

      <Categories filterCategories={filterSearch} categories={categories} />
    </React.Fragment>
  )
}

export default ArticleAside
