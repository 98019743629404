import React from "react"
import articleAsideStyle from "./style.module.scss"

const DatePosted = ({ date }) => {
  return (
    <div
      className={`${articleAsideStyle.container} ${articleAsideStyle.noMarginTop} `}
    >
      <h4 className={articleAsideStyle.text}>POSTED</h4>
      <div className={articleAsideStyle.datePostedDate}>{date}</div>
    </div>
  )
}

export default DatePosted
