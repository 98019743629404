import React from "react"
import articlePageTemplateStyle from "./style.module.scss"
import BackgroundImage from "gatsby-background-image"
import { navigate } from "gatsby"
import ArticleAside from "./article-aside"
import { Link } from "gatsby"
import PreviousAndNextArticle from "./previous-and-next-article"

const ArticlePageTemplate = ({
  wpPost,
  templateData,
  categories,
  fbId,
  older,
  newer,
}) => {
  const filterSearch = (key, val, name) => {
    navigate("/", {
      state: {
        filter: {
          key,
          val,
          name,
        },
      },
    })
  }

  const lightbox = e => {
    if (e.target.tagName === "IMG") {
      document.getElementById(
        "lightbox"
      ).innerHTML = `<img src="${e.target.src}"/>`
      document.getElementById("lightbox").style.visibility = "visible"
      document.getElementById("closeButton").style.visibility = "visible"
    }
  }

  const closeLightbox = () => {
    document.getElementById("lightbox").style.visibility = "hidden"
    document.getElementById("closeButton").style.visibility = "hidden"
  }
  return (
    <BackgroundImage
      Tag="section"
      className="blogPageBg"
      fluid={templateData.backgroundImage.fluid}
      style={{
        backgroundSize: `100% auto`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `top`,
        marginTop: ``,
      }}
    >
      <div className="container">
        <div
          onClick={closeLightbox}
          id="closeButton"
          className={articlePageTemplateStyle.close}
        >
          <div className={articlePageTemplateStyle.xIcon}></div>
        </div>
        <div id="lightbox" className={articlePageTemplateStyle.lightbox}></div>
        <div className={articlePageTemplateStyle.pageStructure}>
          <div>
            <div className={articlePageTemplateStyle.postContainer}>
              <div className={articlePageTemplateStyle.backToAllPostsContainer}>
                <Link
                  className={articlePageTemplateStyle.backToAllPosts}
                  to={"/"}
                >
                  ◀ BACK TO ALL POSTS
                </Link>
              </div>
              <div className={articlePageTemplateStyle.header}>
                <h2
                  className={articlePageTemplateStyle.headerTitle}
                  dangerouslySetInnerHTML={{ __html: wpPost.title }}
                />
              </div>
              <div className={articlePageTemplateStyle.postedBy}>
                <span className={articlePageTemplateStyle.byText}>BY</span>
                <button
                  className={articlePageTemplateStyle.byAuthorLink}
                  onClick={() =>
                    filterSearch("author", wpPost.author.id, wpPost.author.name)
                  }
                >
                  {wpPost.author.name}
                </button>
              </div>
              <div className={articlePageTemplateStyle.summary}>
                <div
                  className={`${articlePageTemplateStyle.summaryDescription} ${articlePageTemplateStyle.summaryDescriptionFullWidth}`}
                >
                  <div
                    onClick={lightbox}
                    className={articlePageTemplateStyle.summaryText}
                    dangerouslySetInnerHTML={{ __html: wpPost.content }}
                  />
                </div>
              </div>
            </div>
            <PreviousAndNextArticle older={older} newer={newer} />
          </div>
          <div className={articlePageTemplateStyle.aside}>
            <ArticleAside
              wpPost={wpPost}
              templateData={templateData}
              categories={categories}
              fbId={fbId}
            />
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default ArticlePageTemplate
